/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.remove-overflow {
    overflow-x: unset !important;
}

div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

div::-webkit-scrollbar-thumb {
    background: transparent;
}

div:hover::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    border-radius: 1.5em;
}
/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.apexcharts-toolbar {
    z-index: 0 !important;
}
.ant-table-header table { table-layout: auto !important; } 
.image-container div {
    transition: transform 0.3s ease-in-out;
}
.image-container div:hover {
    transform: scale(1.1);
}